//* Base ......................
import { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'
//* 3rd party ......................
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useLocalStorage } from 'usehooks-ts'
//* MUI ......................
import Box, { type BoxProps } from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
//* Local Components ......................
import AuthSidebar from 'src/components/auth/Sidebar'
import B2BSidebar from 'src/components/auth/B2BSidebar'
//* Configs ......................
import authConfig from 'src/configs/auth'

type Props = BoxProps

export default function AuthLayout({ ...rest }: Props) {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [storedToken] = useLocalStorage(authConfig.storageTokenKeyName, null, { deserializer: (val) => val })
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY!
    const googleAuthClientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID!
    const isSignup = pathname.includes('signup')
    const isB2B = pathname.includes('b2b')
    const desktopSidebarWidth = 600
    useEffect(() => {
        if (storedToken) navigate('/') //if user is already signin we redirect to '/' page
    }, [storedToken])

    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            <GoogleOAuthProvider clientId={googleAuthClientID}>
                <Box {...rest}>
                    <Box
                        width={{ xs: 1, xl: `calc(100% - ${desktopSidebarWidth}px)` }}
                        minHeight='100vh'
                        p={{ xs: 6, xl: 16 }}
                        mr={{ xs: 0, xl: `${desktopSidebarWidth}px` }}
                        display='flex'
                        flexDirection='column'
                        justifyContent={isSignup ? 'start' : 'center'}
                        alignItems='center'
                    >
                        <Box>
                            <Outlet />
                        </Box>
                    </Box>
                    {!isMobile && (
                        <Box
                            position='fixed'
                            right={0}
                            top={0}
                            zIndex={1}
                            width={{ xs: 1, xl: `${desktopSidebarWidth}px` }}
                            height={{
                                xs: 'auto',
                                lg: '100vh'
                            }}
                        >
                            {!isB2B && <AuthSidebar height={1} />}
                            {isB2B && <B2BSidebar height={1} />}
                        </Box>
                    )}
                </Box>
            </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
    )
}
