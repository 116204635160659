import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import Form from 'src/components/shared/Form'
import ServiceDesc from 'src/components/new-enrichment/enrich/ServiceDesc'
import CreditsOverview from 'src/components/commons/CreditsOverview'
import EnrichFields from 'src/components/new-enrichment/enrich/EnrichFields'
import useIntercomWrapper from 'src/hooks/useIntercomWrapper'
import useGTMWrapper from 'src/hooks/useGTMWrapper'
import { addEnrichment } from 'src/services/new-enrichment'
import type { CompanyContactEnrichService, EnrichFormFields } from 'src/types/enrichment'

type Props = Omit<BoxProps, 'onSubmit'> &
    CompanyContactEnrichService & {
        bulkId: number
        bulkLoading?: boolean
        columnsOptions?: { label: string; value: string }[]
        onSubmit?: () => void
    }

export default function RunService({
    type,
    code,
    bulkId,
    bulkLoading = false,
    credits,
    name,
    description,
    link,
    inputs = [],
    outputs = [],
    fields = [],
    columnsOptions = [],
    onSubmit,
    ...rest
}: Props) {
    const { sendIntercomEvent } = useIntercomWrapper()
    const { sendGTMEvent } = useGTMWrapper()
    const { control, handleSubmit, reset } = useForm<EnrichFormFields>({
        mode: 'onSubmit',
        defaultValues: {
            country: code === 'CUF' ? [{ value: 'us', label: 'United States' }] : [],
            bulkName: '',
            columns: fields.filter((f) => f.type === 'column-select').map((field) => ({ ...field, value: null }))
        }
    })
    const { isPending: enrichLoading, mutateAsync: addEnrich } = useMutation<unknown, Error, EnrichFormFields>({
        mutationFn: async ({ country, bulkName, columns = [] }) => {
            await addEnrichment({
                bulkId,
                service: code,
                countryCode: country?.[0]?.value,
                newBulkName: bulkName,
                inputs: columns.map((c) => c.value?.value as string)
            })
        }
    })
    const onSubmitHandler = async ({ country, bulkName, columns }: EnrichFormFields) => {
        await addEnrich({ country, bulkName, columns })
        sendIntercomEvent({
            event: 'used_service',
            data: { engine: 'enrichment', service: name, request_count: credits }
        })
        sendGTMEvent({
            event: 'list_enrichment',
            data: {
                service_name: 'enrichment',
                service_type: type,
                enrichment: name
            }
        })
        reset()
        onSubmit?.()
    }

    return (
        <Box {...rest}>
            <Form onSubmit={handleSubmit(onSubmitHandler)} style={{ height: '100%' }}>
                <Stack height={1}>
                    {!!description && (
                        <Box px={5}>
                            <ServiceDesc
                                preset='preset-1'
                                title='About Service'
                                description={description}
                                link={link}
                            />
                        </Box>
                    )}
                    <Divider sx={{ my: 5, borderColor: 'newNeutral.light5' }} />
                    <Box px={5}>
                        <Box>
                            <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                                Valid Input
                            </Typography>
                            <Stack mt={4} direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                                {inputs.map((input, i) => (
                                    <Fragment key={input.value}>
                                        <Chip
                                            variant='filled'
                                            size='small'
                                            label={input.label}
                                            sx={{
                                                py: 3.5,
                                                typography: 'newLabelSm',
                                                color: 'newNeutral.main',
                                                bgcolor: 'newGrey.light4',
                                                border: 1,
                                                borderColor: 'newNeutral.light5',
                                                borderRadius: 10
                                            }}
                                        />
                                        {i < inputs.length - 1 && (
                                            <Typography component='span' variant='newBodySm' color='newNeutral.light5'>
                                                /
                                            </Typography>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                        </Box>
                        <Box mt={8}>
                            <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                                Outputs
                            </Typography>
                            <Stack
                                mt={4}
                                p={3}
                                bgcolor='newGrey.light4'
                                border={1}
                                borderRadius={1}
                                borderColor='newNeutral.light5'
                                direction='row'
                                alignItems='center'
                                flexWrap='wrap'
                                gap={2}
                            >
                                {outputs.map((output, i) => (
                                    <Fragment key={output.value}>
                                        <Typography component='span' variant='newLabelSm' color='newNeutral'>
                                            {output.label}
                                        </Typography>
                                        {i < outputs.length - 1 && (
                                            <Typography component='span' variant='newBodySm' color='newNeutral.light5'>
                                                /
                                            </Typography>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 5, borderColor: 'newNeutral.light5' }} />
                    <EnrichFields
                        px={5}
                        control={control}
                        fields={fields}
                        columnsOptions={columnsOptions}
                        bulkLoading={bulkLoading}
                    />
                    <Box flexGrow={1} />
                    <Box mt={5} px={5}>
                        <CreditsOverview justifyContent='center' count={credits} suffix='Credits Usage' />
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            size='xs'
                            color='newNeutral'
                            loading={enrichLoading}
                            sx={{ mt: 3, mb: 5 }}
                        >
                            <Icon icon='ri:sparkling-2-fill' size='md' color='#fff' className='mr-2' />
                            Run Action
                        </Button>
                    </Box>
                </Stack>
            </Form>
        </Box>
    )
}
