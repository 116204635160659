import { useState, useRef, useEffect, type MouseEvent, type FocusEvent, type CSSProperties } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'
import sanitizeHTML from 'sanitize-html'
import { type SxProps } from '@mui/material/styles'
import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import useColor from 'src/hooks/useColor'

export type Preset = 'normal' | 'ai-gradient'
type Props = Omit<BoxProps, 'onChange'> & {
    preset?: Preset
    value: string
    onChange?: (newVal: string) => void
    onFocus?: (e: FocusEvent<HTMLDivElement>) => void
    onBlur?: (e: FocusEvent<HTMLDivElement>) => void
    placeholder?: string
    color?: string
    disabled?: boolean
    readonly?: boolean
    error?: boolean
    helperText?: string
    minHeight?: number
    placeholderSx?: SxProps
    editorContainerSx?: SxProps
    editorStyles?: CSSProperties
}

//? we could manually use <div ref={} contenteditable dangerouslySetInnerHTML={{_html:...}} onInput={}> but cursor position always jumps to start and we should manually handle it ... instead we use 'react-contenteditable' library
//? because user can enter any value for prevent code injection attacks we use 'sanitize-html' to sanitize user input and only allow basis html tags/attributes
//* another approach for this component is that we store editor content like --> [{type:"text",content:"abcd"},{type:"chip",content:"something"}] and we render this array as children of div with contenteditable attribute

const AiPrompt = ({
    preset = 'normal',
    value = '',
    //if we want to set init value prop from parent we should use something like 'abcd' or 'start <span contenteditable='false' class='chip'> start <button class='close'>⨉</button></span> end' means init value must contains html with certain classes
    onChange,
    onFocus,
    onBlur,
    placeholder,
    color = 'newPrimary',
    readonly = false,
    disabled = false,
    error,
    helperText,
    minHeight = 200,
    placeholderSx,
    editorContainerSx,
    editorStyles,
    sx,
    ...rest
}: Props) => {
    const editorRef = useRef<HTMLDivElement>(null!)
    const [hasValue, setHasValue] = useState(false)
    const [focus, setFocus] = useState(false)
    const parsedColor = useColor(color)
    const activeColor = error ? 'newError.main' : focus ? parsedColor : 'newNeutral.light4'
    const generateChip = (text: string) => {
        const textNormalize = text.replace(/(\n|<br>|<div>|<\/div>)/g, '')
        return ` <span contenteditable="false" class="chip"> ${textNormalize} <button class="close">⨉</button></span> `
    }
    const getParsedValue = (newValue: string) => {
        //if user types something like '[something]' we convert it to <span class="...">something <button onClick="...">close</button></span>
        //we use '[]' to not face any conflict but if we would want to use '/something <space>' we could use: /(^|\s|<div>|<div><br><\/div>|<br>)\/(\S+)(\s|\r?\n|<div><br><\/div>|<br>$)/g
        //if we want to use '/something <space>' approach for pass 'value' prop we should use 2 preceding/following space before/after any chip
        const parsedValue = newValue.replace(
            /(\[)(\S+)(\])/g,
            (match, preceding, textContent, following, index, original) => {
                const chipHTML = generateChip(textContent)
                return chipHTML
            }
        ) //we use regex on 'editor' value to convert it to our desire html format
        const sanitizeValue = sanitizeHTML(parsedValue, {
            allowedTags: ['div', 'span', 'button', 'br'],
            allowedAttributes: {
                div: ['class'],
                span: ['contenteditable', 'class'],
                button: ['class']
            },
            parseStyleAttributes: false
        }) //we sanitize html to make sure it does not contain any malicious code
        return sanitizeValue
    }
    const onFocusHandler = (e: FocusEvent<HTMLDivElement>) => {
        setFocus(true)
        onFocus?.(e)
    }
    const onBlurHandler = (e: FocusEvent<HTMLDivElement>) => {
        setFocus(false)
        onBlur?.(e)
    }
    const handleChange = (e: ContentEditableEvent) => {
        const newValue = e.target.value
        const parsedValue = getParsedValue(newValue)
        onChange?.(parsedValue)
        //editorRef.current.innerHTML = parsedValue //! if we use this caret will go to start
    }
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        //for closing chip we define click event on whole editor and then check e.target for identify specific element that we click on
        const target = e.target as HTMLElement
        //we could check things like --> target.tagName.toLowerCase() === 'button' , target.parentElement?.tagName.toLowerCase==='span'
        if (target.classList.contains('close')) {
            const chip = target.parentElement
            if (chip) {
                chip.remove()
                onChange?.(editorRef.current.innerHTML) //update editor content after DOM updates
            }
        }
    }
    useEffect(() => {
        //* set editor content base on value prop
        setTimeout(() => {
            //? we must set it inside 'setTimeout' because value can contain interactive html and we must wait before setting value of editor
            handleChange({ target: { value } } as ContentEditableEvent)
        }, 0)
        //* check if editor has value or not
        const cleanValue = value.replace(/(<div>|<\/div>|<br>|<br >|<br\/>|<br \/>)/g, '')
        setHasValue(!!cleanValue.length)
    }, [value])

    return (
        <Box {...rest}>
            <Box
                width={1}
                height='auto'
                overflow='hidden'
                bgcolor='transparent'
                color='newNeutral'
                typography='newBodySm'
                p={0} //should not change it , if we want to add padding we should use it on div[contenteditable]
                border={preset === 'ai-gradient' && !focus && !error ? 'none' : focus ? 2 : 1}
                borderColor={activeColor}
                borderRadius={1}
                position='relative'
                onClick={handleClick}
                sx={{
                    outline: 'none',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    pointerEvents: readonly || disabled ? 'none' : 'initial',
                    opacity: disabled ? 0.65 : 1,
                    ...editorContainerSx
                }}
                className={`editor-prompt ${focus ? 'focus' : ''} ${!!error ? 'error' : ''} ${preset === 'ai-gradient' ? 'ai-gradient-border' : ''}`}
            >
                {!hasValue && (
                    <Typography
                        sx={{
                            pointerEvents: 'none',
                            typography: 'newBodySm',
                            position: 'absolute',
                            left: '.75rem',
                            top: '.75rem',
                            color: preset === 'ai-gradient' ? 'transparent' : 'newNeutral.light4',
                            backgroundClip: preset === 'ai-gradient' ? 'text' : 'initial',
                            backgroundImage:
                                preset === 'ai-gradient'
                                    ? 'linear-gradient(to right,#A972EB ,#ECB245 ,#7DAAD0, #FB9898)'
                                    : 'initial',
                            ...placeholderSx
                        }}
                    >
                        {placeholder}
                    </Typography>
                )}
                <ContentEditable
                    innerRef={editorRef}
                    disabled={readonly || disabled}
                    html={value}
                    onChange={handleChange}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    tagName='div'
                    style={{
                        width: '100%',
                        height: '100%',
                        minHeight: `${minHeight}px`,
                        padding: '.75rem',
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        cursor: 'text',
                        ...editorStyles
                    }}
                />
            </Box>
            {!!helperText && (
                <FormHelperText error={error} sx={{ mt: 2 }}>
                    {helperText}
                </FormHelperText>
            )}
        </Box>
    )
}

export default AiPrompt

//* Example#1: basic usage
// const [val, setVal] = useState('')
// <AiPrompt value={val} onChange={(v) => setVal(v)} placeholder='Enter...' />
//* Example#2: validate use react-hook-form
{
    /* <Controller control={control} name='prompt'
    rules={{
        validate: (val) => {
            const cleanValue = val.replace(/(<div>|<\/div>|<br>|<br >|<br\/>|<br \/>)/g, '')
            return cleanValue.trim().length ? true : 'field is required'
        }
    }}
    render={({ field, fieldState }) => (
        <AiPrompt value={field.value} onChange={(val) => field.onChange(val)} placeholder='placeholder'
            error={!!fieldState.error} helperText={fieldState.error?.message} editorSx={{ minHeight: 100 }}
        />
    )}
/> */
}
