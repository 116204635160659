import { Controller, type Control } from 'react-hook-form'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Icon from 'src/components/shared/Icon'
import Textfield from 'src/components/shared/Textfield'
import AutoComplete, { type Option } from 'src/components/shared/AutoComplete'
import CountrySelect, { type CountryOption } from 'src/components/wrappers/CountrySelect'
import type { EnrichFormFields, Field } from 'src/types/enrichment'

type Props = BoxProps & {
    control: Control<EnrichFormFields>
    fields: Field[]
    columnsOptions: Option[]
    bulkLoading?: boolean
}

export default function EnrichFields({
    control, //we pass whole react-hook-form 'control' so no need for any extra 'value','onChange' prop on any of form fields and everything will handle with this 'control' prop
    fields = [],
    columnsOptions = [],
    bulkLoading = false,
    ...rest
}: Props) {
    const columnFields = fields.filter((f) => f.type === 'column-select')
    const otherFields = fields.filter((f) => f.type !== 'column-select')

    return (
        <Box {...rest}>
            <Box>
                <Stack direction='row' alignItems='center' gap={2}>
                    <Typography component='h3' variant='newLabelMd' color='newNeutral'>
                        Column Mapping
                    </Typography>
                    <Tooltip
                        arrow
                        placement='bottom'
                        title='To put the right information in each column, just click on “Select” and pick the matching field. For example, if your spreadsheet has a column named “Company Websites”, choose as “Company Domain”.'
                        slotProps={{
                            tooltip: {
                                sx: { bgcolor: 'newNeutral.main', color: 'white' }
                            }
                        }}
                    >
                        <Box>
                            <Icon icon='lucide:info' size='sm' color='newNeutral' style={{ display: 'flex' }} />
                        </Box>
                    </Tooltip>
                </Stack>
                <Stack mt={5} gap={5}>
                    {/* For columns mapping ...  */}
                    {columnFields.map((columnField, i) => (
                        <Box key={columnField.key}>
                            <Typography mb={3} component='p' variant='newLabelSm' color='newNeutral'>
                                {columnField.label}
                            </Typography>
                            <Controller
                                control={control}
                                name={`columns.${i}.value`}
                                rules={{ required: 'field is required' }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <AutoComplete
                                            {...field}
                                            onChange={(_, val) => field.onChange(val)}
                                            options={columnsOptions}
                                            loading={bulkLoading}
                                            variant='outlined'
                                            size='xs'
                                            color='newNeutral'
                                            borderColor='newNeutral.light4'
                                            placeholder='Select...'
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )
                                }}
                            />
                        </Box>
                    ))}
                    {/* For bulk name & country select & ...  */}
                    {otherFields.map((serviceField) => (
                        <Box key={serviceField.key}>
                            <Typography mb={3} component='p' variant='newLabelSm' color='newNeutral'>
                                {serviceField.label}
                            </Typography>
                            <Controller
                                control={control}
                                name={serviceField.type === 'bulk-name' ? 'bulkName' : 'country'}
                                rules={{ required: 'field is required' }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <>
                                            {serviceField.type === 'bulk-name' && (
                                                <Textfield
                                                    {...field}
                                                    variant='outlined'
                                                    size='xs'
                                                    color='newNeutral'
                                                    borderColor='newNeutral.light4'
                                                    placeholder='Enter...'
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                            {serviceField.type === 'country-select' && (
                                                <CountrySelect
                                                    value={field.value as CountryOption[]}
                                                    onChange={(_, val) => field.onChange(val)}
                                                    variant='outlined'
                                                    size='xs'
                                                    color='newNeutral'
                                                    borderColor='newNeutral.light4'
                                                    placeholder='Select'
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        </>
                                    )
                                }}
                            />
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Box>
    )
}
