import { Helmet } from 'react-helmet-async'
import themeConfig from 'src/configs/themeConfig'

export type Props = {
    title: string
    description?: string
    route?: string
    appendTitleTemplate?: boolean
}

export default function Meta({
    title,
    description = 'CUFinder - The ultimate lead generation platform',
    route,
    appendTitleTemplate = true
}: Props) {
    const finalTitle = appendTitleTemplate ? `${title} | ${themeConfig.templateName}` : title
    const finalRoute = route ? `https://dashboard.cufinder.io${route}` : null

    return (
        <Helmet>
            <title>{finalTitle}</title>
            <meta name='description' content={description} />
            <meta property='og:title' content={finalTitle} />
            <meta property='og:description' content={description} />
            <meta name='twitter:title' content={finalTitle} />
            <meta name='twitter:description' content={description} />
            {!!finalRoute && <link rel='canonical' href={finalRoute} />}
        </Helmet>
    )
}

//? Usage:
//<Meta title="Page Title" description='Page Description' />
//? Tip: we can use <Helmet> inside any component and even one page can consist multiple <Helmet> ... we can add any <link /> , <script /> inside <Helmet> too
