import { Fragment, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import CircularProgress from 'src/components/shared/CircularProgress'
import NoResult from 'src/components/shared/NoResult'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import DottedBackground from 'src/components/shared/DottedBackground'
import ViewportManager from 'src/components/shared/ViewportManager'
import OverviewCard, { type Overview } from 'src/components/new-enrichment/overview/Card'
import { getEnrichments } from 'src/services/new-enrichment'
import servicesJson from 'src/data/enrichment/list.json'
import type { EnrichService } from 'src/types/enrichment'

export enum Type {
    IMPORT = 'import',
    ENRICH = 'enrich'
}
type Props = Omit<BoxProps, 'color'> & {
    bulkId: number
}

const intl = Intl.NumberFormat('en-US')
const services = servicesJson as EnrichService[]

export default function OverviewList({ bulkId, ...rest }: Props) {
    const { isFetching: enrichesLoading, data: enriches } = useQuery({
        initialData: [],
        queryKey: ['get-bulk-enriches', bulkId],
        queryFn: async () => {
            const { items } = await getEnrichments({ bulkId })
            return items
        }
    })
    const enrichOverviews = useMemo<Overview[]>(() => {
        const firstItem = enriches[0] || {}
        const { type } = services.find((service) => service.code === firstItem.serviceCode)! || {}
        const firstOverview: Overview = {
            title: type === 'company' ? 'Import list of companies' : 'Import list of people',
            description: 'File import',
            date: firstItem.startDate,
            icon: 'iconoir:import',
            iconColor: 'newPastelGreen.dark'
        }
        const overviews: Overview[] = enriches.flatMap((enrich) => {
            const { type, name, icon, color } = services.find((service) => service.code === enrich.serviceCode)!
            return [
                {
                    title: name,
                    description: `${intl.format(enrich.rowCounts)} rows`,
                    date: enrich.endDate,
                    credits: enrich.creditsUsage,
                    icon: icon,
                    iconColor: color
                }
            ]
        })
        return [firstOverview, ...overviews]
    }, [enriches])

    return (
        <Box
            p={5}
            display='flex'
            flexDirection='column'
            overflow='auto'
            bgcolor='white'
            border={1}
            borderColor='newNeutral.light5'
            borderRadius={1}
            className='custom-scrollbar'
            {...rest}
        >
            <DottedBackground color='newNeutral.light5' space={10} size={1} p={2} flexGrow={1}>
                <ViewportManager
                    p={2}
                    sidebarPosition='sticky'
                    allowZoomReset
                    allowZoomFullscreen={false}
                    fullScreenSx={{ bgcolor: 'white' }}
                >
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        {enrichesLoading && <CircularProgress variant='indeterminate' size={120} />}
                        {!!(!enrichesLoading && !enrichOverviews.length) && <NoResult />}
                        {!!(!enrichesLoading && enrichOverviews.length) && (
                            <List
                                sx={{
                                    maxWidth: { xs: '100%', md: 500 },
                                    mx: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    gap: 0
                                }}
                            >
                                {enrichOverviews.map((overview, idx) => (
                                    <Fragment key={idx}>
                                        <ListItem sx={{ p: 0 }}>
                                            <OverviewCard {...overview} sx={{ width: 1 }} />
                                        </ListItem>
                                        {idx < enrichOverviews.length - 1 && (
                                            <ListItem sx={{ p: 0 }}>
                                                <img
                                                    src='/images/new-theme/others/arrow-black-down.png'
                                                    alt='arrow'
                                                    style={{ margin: '0 auto', height: '60px' }}
                                                />
                                            </ListItem>
                                        )}
                                    </Fragment>
                                ))}
                            </List>
                        )}
                    </Box>
                </ViewportManager>
            </DottedBackground>
        </Box>
    )
}
