import type { Response } from 'src/types/Server'

//* Common -----------------------
export type Type = 'company' | 'contact' | 'ai' | 'normalize' | 'free'
export type BulkType = 'excel' | 'company' | 'contact' | 'map'
export enum Status {
    PENDING = 0,
    PROCESSED = 1,
    NEW = 2
}
export type FileType = 'xls' | 'xlsx' | 'csv'
export type Option = {
    label: string
    value: string
}
//* Bulks -----------------------
export type CellData = {
    _id: string
    [key: string]: unknown
}
export type UploadBulk = {
    type: null | Type
    name: string
    file: null | File
}
export type Bulk = {
    id: number
    createdAt: string
    updatedAt: string
    type: Type
    code?: ServiceCode
    status: Status
    name: string
    rowCount: number
    cellsData: CellData[]
    cellsDataNormalize: unknown[][]
}
export type BulkResponse = {
    _id: string
    user_id: number
    bulk_id: number
    type: Type
    current_service?: null | ServiceCode
    name: string
    status: Status
    rows_count: number
    creation_date: Date
    last_update: Date
    cells_data?: CellData[]
}
export type AddBulkReqBody = {
    type: Type
    name: string
    cells: unknown[][]
}
export type GetBulkFilter = {
    id?: number | string
}
export type GetBulkResponse = Response & { data: { bulk: BulkResponse } }
export type GetBulksFilters = {
    page?: number
    pageSize?: number
}
export type GetBulks = {
    items: Bulk[]
    totalCount: number
}
export type GetBulksResponse = Response & {
    data: { bulks: BulkResponse[]; total_count: number }
}
export type DeleteBulkFilter = {
    id: number | string
}
export type DeleteBulkRowsFilter = {
    bulkId: number
    rowIds: string[]
}
//* Enrichments -----------------------
export type AiModel = 'solara' | 'vespera' | 'lucero'
export type AiModelOption = {
    value: AiModel
    label: string
    credits: number
    recommended?: boolean
}
export type ServiceCode =
    | 'CUF'
    | 'ENC'
    | 'LCI'
    | 'FCL'
    | 'ELF'
    | 'CND'
    | 'CAR'
    | 'FPA'
    | 'FEA'
    | 'FCC'
    | 'FTS'
    | 'EPP'
    | 'EPE'
    | 'FWE'
    | 'TEP'
    | 'ai-web-crawler'
    | 'extract-b2b-customers-from-domain'
    | 'find-company-instagram-account'
    | 'find-business-phone-number'
    | 'find-company-career-page'
    | 'check-company-saas'
    | 'check-company-demo'
    | 'find-organization-structure-type'
    | 'normalize-domain'
    | 'normalize-company-name'
    | 'normalize-person-name'
    | 'normalize-phone-number'
    | 'normalize-location-address'
    | 'flatten-list-into-separate-rows'
    | 'find-domain-from-website'
export type InputOutput = { value: string; label: string }
export type Field = {
    type: 'bulk-name' | 'column-select' | 'country-select'
    key: string
    label: string
}
export type EnrichFormFields = {
    country: Option[]
    bulkName: string
    columns: (Field & { value: null | Option })[]
}
export type BaseEnrichService = {
    disabled?: boolean
    name: string
    credits: number
    description?: string
    link?: string
    icon?: string
    color?: string
    imgSrc?: string
}
export type CompanyContactEnrichService = BaseEnrichService & {
    type: Extract<Type, 'company' | 'contact'>
    code: Extract<
        ServiceCode,
        | 'CUF'
        | 'ENC'
        | 'LCI'
        | 'FCL'
        | 'ELF'
        | 'CND'
        | 'CAR'
        | 'FPA'
        | 'FEA'
        | 'FCC'
        | 'FTS'
        | 'EPP'
        | 'EPE'
        | 'FWE'
        | 'TEP'
    >
    inputs?: InputOutput[]
    outputs?: InputOutput[]
    fields?: Field[]
}
export type AIEnrichService = BaseEnrichService & {
    type: Extract<Type, 'ai'>
    code: Extract<
        ServiceCode,
        | 'ai-web-crawler'
        | 'extract-b2b-customers-from-domain'
        | 'find-company-instagram-account'
        | 'find-business-phone-number'
        | 'find-company-career-page'
        | 'check-company-saas'
        | 'check-company-demo'
        | 'find-organization-structure-type'
    >
    model?: AiModel
    prompt?: string
    outputs?: string[]
}
export type NormalizeEnrichService = BaseEnrichService & {
    type: Extract<Type, 'normalize'>
    code: Extract<
        ServiceCode,
        | 'normalize-domain'
        | 'normalize-company-name'
        | 'normalize-person-name'
        | 'normalize-phone-number'
        | 'normalize-location-address'
    >
}
export type FreeEnrichService = BaseEnrichService & {
    type: Extract<Type, 'free'>
    code: Extract<ServiceCode, 'flatten-list-into-separate-rows' | 'find-domain-from-website'>
}
export type EnrichService = CompanyContactEnrichService | AIEnrichService | NormalizeEnrichService | FreeEnrichService
export type Enrich = {
    bulkId: number
    serviceCode: ServiceCode
    passed: number
    rowCounts: number
    creditsUsage: number
    startDate: null | string
    endDate: null | string
}
export type EnrichResponse = {
    bulk_id: number
    service: ServiceCode
    passed: number
    rows_count: number
    used_credits: number
    start_time: null | Date
    end_time: null | Date
}
export type AddEnrichment = Pick<Enrich, 'bulkId'>
export type AddEnrichmentReqBody = {
    bulkId: number
    inputs: string[]
    service: ServiceCode
    countryCode?: string
    newBulkName?: string
}
export type AddEnrichmentResponse = Response & {
    data: Pick<EnrichResponse, 'bulk_id'>
}
export type GetEnrichesFilter = {
    bulkId: number
}
export type GetEnriches = {
    items: Enrich[]
}
export type GetEnrichesResponse = Response & {
    data: {
        actions: EnrichResponse[]
    }
}
//* Sockets -----------------------
export type SocketBulkActions = 'get_bulk' | 'bulk_info_update'
export type SocketBulk = Bulk & { isExist: boolean; processingColumns: string[] }
export type SocketBulkResponse = {
    exists: boolean
    columns?: string[]
    bulk: BulkResponse
}
export type SocketGetBulkFilter = { id: number }
