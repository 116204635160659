import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { IntercomProvider } from 'react-use-intercom'
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'
import IntercomLauncher from 'src/layouts/IntercomLauncher'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

const RootLayout = () => {
    const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID!

    return (
        <SettingsProvider>
            <SettingsConsumer>
                {({ settings }) => {
                    return (
                        <ThemeComponent settings={settings}>
                            <IntercomProvider
                                appId={intercomAppId}
                                apiBase='https://api-iam.intercom.io'
                                autoBoot
                                autoBootProps={{
                                    actionColor: '#01077A',
                                    backgroundColor: '#2667FF',
                                    verticalPadding: 160,
                                    hideDefaultLauncher: true,
                                    customLauncherSelector: '#intercom-chat-popup'
                                }}
                            >
                                <IntercomLauncher />
                                <Outlet />
                            </IntercomProvider>
                            <ReactHotToast>
                                <Toaster
                                    position={settings.toastPosition}
                                    toastOptions={{ className: 'react-hot-toast' }}
                                    reverseOrder
                                />
                            </ReactHotToast>
                        </ThemeComponent>
                    )
                }}
            </SettingsConsumer>
        </SettingsProvider>
    )
}

export default RootLayout
