import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from 'src/components/shared/Link'

export type Preset = 'preset-1' | 'preset-2'
type Props = BoxProps & {
    preset?: Preset
    title?: string
    description: string
    link?: string
}

export default function ServiceDesc({
    preset = 'preset-1',
    title = 'About Service',
    description,
    link,
    sx,
    ...rest
}: Props) {
    return (
        <Box
            overflow='hidden'
            p={4}
            borderRadius={1}
            className={`${preset === 'preset-1' ? 'radial-red-orange' : preset === 'preset-2' ? 'radial-purple-blue-yellow' : ''}`}
            sx={{
                ...sx
            }}
            {...rest}
        >
            <Typography component='h5' variant='newLabelMd' color='newNeutral'>
                {title}
            </Typography>
            {!!description && (
                <Typography mt={3} component='p' variant='newBodySm' color='newNeutral'>
                    {description}
                </Typography>
            )}
            {!!link && (
                <Link
                    target='_blank'
                    href={link}
                    size='xs'
                    sx={{ mt: 3, display: 'inline-block', bgcolor: 'white', color: 'newNeutral.main', boxShadow: 2 }}
                >
                    Learn More
                </Link>
            )}
        </Box>
    )
}
