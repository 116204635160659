import { useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Stack, { type StackProps } from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import Icon from 'src/components/shared/Icon'
import useMediaQuery from '@mui/material/useMediaQuery'
import servicesJson from 'src/data/enrichment/list.json'
import type { Type, EnrichService } from 'src/types/enrichment'

type ServiceType = { isNew?: boolean; type: Type; title: string; icon: string; items: EnrichService[] }
type Props = StackProps & {
    onServiceClick?: (service: EnrichService) => void
}

const servicesList = servicesJson as EnrichService[]

export default function AddEnrichment({ onServiceClick, ...rest }: Props) {
    const isMobile = useMediaQuery('(width < 600px)')
    const [tab, setTab] = useState<Type>('company')
    const services = useMemo<ServiceType[]>(() => {
        return [
            {
                type: 'company',
                title: 'Company',
                icon: 'ph:buildings',
                items: servicesList.filter((s) => s.type === 'company')
            },
            {
                type: 'contact',
                title: 'People',
                icon: 'ph:users-three',
                items: servicesList.filter((s) => s.type === 'contact')
            },
            {
                type: 'ai',
                isNew: true,
                title: 'AI Agent ',
                icon: 'ph:sparkle',
                items: servicesList.filter((s) => s.type === 'ai')
            },
            {
                type: 'normalize',
                title: 'Normalize',
                icon: 'ph:paint-brush-household',
                items: servicesList.filter((s) => s.type === 'normalize')
            },
            {
                type: 'free',
                title: 'Free Tools',
                icon: 'ph:wrench',
                items: servicesList.filter((s) => s.type === 'free')
            }
        ]
    }, [servicesList])
    const activeServiceItems = services.find((s) => s.type === tab)?.items || []

    return (
        <Stack direction={isMobile ? 'column' : 'row'} {...rest}>
            <Box flexShrink={0} pl={0} pr={isMobile ? 0 : 5} py={5} borderRight={1} borderColor='newNeutral.light5'>
                <Tabs
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    variant={isMobile ? 'scrollable' : 'standard'}
                    value={tab}
                    onChange={(_, val) => setTab(val)}
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: 'newNeutral.main',
                            height: isMobile ? '2px !important' : '30px !important',
                            transform: !isMobile ? 'translateY(30%)' : undefined,
                            borderRadius: '1rem',
                            left: 0,
                            right: 'initial'
                        }
                    }}
                    sx={{ border: 'none' }}
                >
                    {services.map((service) => {
                        const isActive = tab === service.type
                        return (
                            <Tab
                                key={service.type}
                                value={service.type}
                                label={
                                    <Stack direction='row' alignItems='center' gap={2}>
                                        <Icon
                                            icon={service.icon}
                                            size='lg'
                                            color={isActive ? 'newNeutral' : 'newNeutral.light2'}
                                        />
                                        <Typography
                                            component='h6'
                                            variant='newLabelMd'
                                            color={isActive ? 'newNeutral' : 'newNeutral.light2'}
                                        >
                                            {service.title}
                                        </Typography>
                                        {service.isNew && (
                                            <Box
                                                borderRadius={2}
                                                className='radial-red-blue-purple-yellow'
                                                sx={{
                                                    px: 2.5,
                                                    py: 0.5,
                                                    typography: 'newLabelSm',
                                                    textTransform: 'capitalize',
                                                    color: 'white'
                                                }}
                                            >
                                                New
                                            </Box>
                                        )}
                                    </Stack>
                                }
                                sx={{ pl: 8, py: 3, minHeight: 0, flexDirection: 'row', justifyContent: 'start' }}
                            ></Tab>
                        )
                    })}
                </Tabs>
            </Box>
            <Box flexGrow={1} pl={isMobile ? 0 : 5} pr={0} py={5}>
                <List>
                    {activeServiceItems.map((item) => (
                        <ListItem key={item.code} sx={{ py: 0 }}>
                            <ListItemButton
                                onClick={() => onServiceClick?.(item)}
                                sx={{ borderRadius: 1, py: 2.5, gap: 2 }}
                            >
                                <Stack
                                    flexShrink={0}
                                    width={30}
                                    height={30}
                                    border={1}
                                    borderColor='newNeutral.light5'
                                    borderRadius='50%'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    {!!item.icon && <Icon icon={item.icon} color={item.color!} size='sm' />}
                                    {!!item.imgSrc && (
                                        <img
                                            src={item.imgSrc}
                                            alt={item.name}
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    )}
                                </Stack>
                                <Typography component='p' variant='newBodySm' color='newNeutral.main'>
                                    {item.name}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Stack>
    )
}
