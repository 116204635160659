import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import Form from 'src/components/shared/Form'
import Textfield from 'src/components/shared/Textfield'
import EnrichFields from 'src/components/new-enrichment/enrich/EnrichFields'
import CreditsOverview from 'src/components/commons/CreditsOverview'
import type { CompanyContactEnrichService, EnrichFormFields } from 'src/types/enrichment'
import { Stack } from '@mui/material'

type Props = BoxProps &
    CompanyContactEnrichService & {
        bulkId: number
        bulkLoading?: boolean
        columnsOptions: { label: string; value: string }[]
    }

export default function TestService({
    type,
    code,
    bulkId,
    bulkLoading = false,
    name,
    credits,
    outputs = [],
    fields = [],
    columnsOptions = [],
    ...rest
}: Props) {
    const result = 'www.amazon.com' //need to fetch it from server
    const [selectedOutputs, setSelectedOutputs] = useState<string[]>([])
    const { control, formState, handleSubmit, reset } = useForm<EnrichFormFields>({
        mode: 'onSubmit',
        defaultValues: {
            country: code === 'CUF' ? [{ value: 'us', label: 'United States' }] : [],
            bulkName: '',
            columns: fields.filter((f) => f.type === 'column-select').map((field) => ({ ...field, value: null }))
        }
    })
    const selectedOutputsValidation = {
        error: formState.isSubmitted && !selectedOutputs.length,
        message: formState.isSubmitted && !selectedOutputs.length ? 'At least select 1 output' : ''
    }
    const onSubmit = ({ country, bulkName, columns = [] }: EnrichFormFields) => {
        setSelectedOutputs([]) //reset outputs
        reset()
    }

    return (
        <Box {...rest}>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Stack height={1}>
                    <Box px={5}>
                        <EnrichFields
                            control={control}
                            fields={fields}
                            columnsOptions={columnsOptions}
                            bulkLoading={bulkLoading}
                        />
                        <Box mt={8}>
                            <Typography component='p' variant='newLabelSm' color='newNeutral'>
                                Output
                            </Typography>
                            <Box>
                                <List>
                                    {outputs.map((output) => {
                                        const isSelected = selectedOutputs.includes(output.value)
                                        return (
                                            <ListItem
                                                key={output.value}
                                                sx={{
                                                    mt: 3,
                                                    p: 2,
                                                    border: isSelected ? 2 : 1,
                                                    borderRadius: 1,
                                                    borderColor: isSelected ? 'newNeutral.main' : 'newNeutral.light4',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    setSelectedOutputs((old) =>
                                                        isSelected
                                                            ? old.filter((o) => o !== output.value)
                                                            : [...old, output.value]
                                                    )
                                                }
                                            >
                                                <Typography component='p' variant='newLabelSm' color='newNeutral'>
                                                    {output.label}
                                                </Typography>
                                                <Icon
                                                    icon={isSelected ? 'mdi:check-circle' : 'mdi-light:circle'}
                                                    size='md'
                                                    color={isSelected ? 'newNeutral' : 'newNeutral.light4'}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                <FormHelperText error={selectedOutputsValidation.error}>
                                    {selectedOutputsValidation.message}
                                </FormHelperText>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 5, borderColor: 'newNeutral.light5' }} />
                    <Box px={5}>
                        <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                            Result
                        </Typography>
                        <Textfield
                            fullWidth
                            value={result}
                            readOnly
                            variant='outlined'
                            size='xs'
                            bgcolor='newGrey.light4'
                            borderColor='newNeutral.light5'
                            sx={{ mt: 3 }}
                        />
                    </Box>
                    <Box flexGrow={1} />
                    <Box mt={5} px={5}>
                        <CreditsOverview justifyContent='center' count={credits} suffix='Credits Usage' />
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            size='xs'
                            color='newNeutral'
                            sx={{ mt: 3, mb: 5 }}
                        >
                            <Icon icon='ri:sparkling-2-fill' size='md' color='#fff' className='mr-2' />
                            Test Service
                        </Button>
                    </Box>
                </Stack>
            </Form>
        </Box>
    )
}
