import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import Form from 'src/components/shared/Form'
import Textfield from 'src/components/shared/Textfield'
import AutoComplete from 'src/components/shared/AutoComplete'
import AiPrompt from 'src/components/shared/AiPrompt'
import CreditsOverview from 'src/components/commons/CreditsOverview'
import ServiceDesc from 'src/components/new-enrichment/enrich/ServiceDesc'
import type { AiModelOption, AIEnrichService, BaseEnrichService } from 'src/types/enrichment'

type Output = {
    name: string
}
type Fields = {
    model: AiModelOption[]
    prompt: string
    outputs: Output[]
}
type Props = Omit<BoxProps, 'onSubmit'> &
    AIEnrichService &
    BaseEnrichService & {
        bulkId: number
        onSubmit?: () => void
    }

const aiModels: AiModelOption[] = [
    {
        value: 'solara',
        label: 'Solara',
        credits: 1,
        recommended: false
    },
    {
        value: 'vespera',
        label: 'Vespera',
        credits: 2,
        recommended: false
    },
    {
        value: 'lucero',
        label: 'Lucero',
        credits: 3,
        recommended: true
    }
]

export default function AiEnrichment({
    bulkId,
    disabled = false,
    code,
    name,
    description,
    link,
    credits,
    model,
    prompt,
    outputs = [],
    onSubmit,
    ...rest
}: Props) {
    const { control, handleSubmit, reset } = useForm<Fields>({
        mode: 'onSubmit',
        defaultValues: {
            model: model ? [aiModels.find((m) => m.value === model)] : [],
            prompt: prompt || '',
            outputs: outputs.length ? outputs.map((o) => ({ name: o })) : [{ name: '' }]
        }
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'outputs'
    })
    const onSubmitHandler = ({ model, prompt, outputs }: Fields) => {
        reset()
        onSubmit?.()
    }

    return (
        <Box {...rest}>
            <Form onSubmit={handleSubmit(onSubmitHandler)} style={{ height: '100%' }}>
                <Stack height={1} gap={5}>
                    <Box>
                        {!!description && (
                            <Box px={5}>
                                <ServiceDesc
                                    preset='preset-2'
                                    title='About Service'
                                    description={description}
                                    link={link}
                                />
                            </Box>
                        )}
                        <Divider sx={{ my: 5, borderColor: 'newNeutral.light5' }} />
                        <Box px={5}>
                            <Box>
                                <Stack direction='row' alignItems='center' gap={2}>
                                    <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                                        Model
                                    </Typography>
                                    <Tooltip
                                        arrow
                                        placement='bottom'
                                        title={'tooltip content'}
                                        slotProps={{ tooltip: { sx: { bgcolor: 'newNeutral.main', color: '#fff' } } }}
                                    >
                                        <Box display='inline-flex'>
                                            <Icon icon='lucide:info' color='newNeutral' size='sm' />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Box mt={3}>
                                    <Controller
                                        control={control}
                                        name='model'
                                        rules={{ required: 'model is required' }}
                                        render={({ field, fieldState }) => (
                                            <AutoComplete
                                                {...field}
                                                onChange={(_, val) => field.onChange([val.at(-1)])}
                                                options={aiModels}
                                                multiple
                                                variant='outlined'
                                                size='xs'
                                                color='newEnrichment'
                                                borderColor='newNeutral.light4'
                                                placeholder='Select model'
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                sx={{ '& .MuiInputBase-root': { height: 'auto' } }}
                                                renderOption={(props, option) => {
                                                    const { key, ...rest } = props
                                                    return (
                                                        <ListItem key={key} {...rest}>
                                                            <Stack direction='row' alignItems='center' gap={2}>
                                                                <Box
                                                                    width={25}
                                                                    height={25}
                                                                    borderRadius='50%'
                                                                    overflow='hidden'
                                                                >
                                                                    <img
                                                                        src='/images/new-theme/others/stars-shine.png'
                                                                        alt='ai'
                                                                        style={{ width: '100%', height: '100%' }}
                                                                    />
                                                                </Box>
                                                                <Typography
                                                                    component='span'
                                                                    variant='newLabelSm'
                                                                    color='newNeutral'
                                                                >
                                                                    CUFinderX
                                                                </Typography>
                                                                <Icon
                                                                    icon='lucide:chevron-right'
                                                                    color='newNeutral.light4'
                                                                    size='sm'
                                                                />
                                                                <Typography
                                                                    component='span'
                                                                    variant='newLabelSm'
                                                                    color='newNeutral'
                                                                >
                                                                    {option.label} ({option.credits} credits)
                                                                </Typography>
                                                                {option.recommended && (
                                                                    <Typography
                                                                        component='span'
                                                                        variant='newLabelSm'
                                                                        color='newNeutral.light3'
                                                                    >
                                                                        - &nbsp;&nbsp;Recommended
                                                                    </Typography>
                                                                )}
                                                            </Stack>
                                                        </ListItem>
                                                    )
                                                }}
                                                renderTags={(value, getTagProps) => {
                                                    return value.map((val, index) => {
                                                        const { key, ...rest } = getTagProps({ index })
                                                        return (
                                                            <Stack
                                                                key={key}
                                                                {...rest}
                                                                direction='row'
                                                                alignItems='center'
                                                                gap={2}
                                                            >
                                                                <Box
                                                                    width={25}
                                                                    height={25}
                                                                    borderRadius='50%'
                                                                    overflow='hidden'
                                                                >
                                                                    <img
                                                                        src='/images/new-theme/others/stars-shine.png'
                                                                        alt='ai'
                                                                        style={{ width: '100%', height: '100%' }}
                                                                    />
                                                                </Box>
                                                                <Typography
                                                                    component='span'
                                                                    variant='newLabelSm'
                                                                    color='newNeutral'
                                                                >
                                                                    CUFinderX
                                                                </Typography>
                                                                <Icon
                                                                    icon='lucide:chevron-right'
                                                                    color='newNeutral.light4'
                                                                    size='sm'
                                                                />
                                                                <Typography
                                                                    component='span'
                                                                    variant='newLabelSm'
                                                                    color='newNeutral'
                                                                >
                                                                    {val.label} ({val.credits} credits)
                                                                </Typography>
                                                                {val.recommended && (
                                                                    <Typography
                                                                        component='span'
                                                                        variant='newLabelSm'
                                                                        color='newNeutral.light3'
                                                                    >
                                                                        - &nbsp;&nbsp;Recommended
                                                                    </Typography>
                                                                )}
                                                            </Stack>
                                                        )
                                                    })
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box mt={10}>
                                <Stack direction='row' alignItems='center' gap={2}>
                                    <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                                        Prompt
                                    </Typography>
                                    <Tooltip
                                        arrow
                                        placement='bottom'
                                        title={'tooltip content'}
                                        slotProps={{ tooltip: { sx: { bgcolor: 'newNeutral.main', color: '#fff' } } }}
                                    >
                                        <Box display='inline-flex'>
                                            <Icon icon='lucide:info' color='newNeutral' size='sm' />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Typography mt={5} component='p' variant='newLabelSm' color='newNeutral'>
                                    Type{' '}
                                    <Box
                                        component='span'
                                        bgcolor='newSurface.light'
                                        border={1}
                                        borderColor='newNeutral.light5'
                                        borderRadius={0.5}
                                        px={2}
                                        py={1}
                                    >
                                        [column-name]
                                    </Box>{' '}
                                    to insert column.
                                </Typography>
                                <Box mt={4}>
                                    <Controller
                                        control={control}
                                        name='prompt'
                                        rules={{
                                            validate: (val) => {
                                                const cleanValue = val.replace(
                                                    /(<div>|<\/div>|<br>|<br >|<br\/>|<br \/>)/g,
                                                    ''
                                                )
                                                return cleanValue.trim().length ? true : 'AI prompt is required'
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <AiPrompt
                                                preset='ai-gradient'
                                                disabled={disabled}
                                                minHeight={100}
                                                value={field.value}
                                                onChange={(val) => field.onChange(val)}
                                                placeholder='Tell me if this is b2b or b2c business'
                                                color='newEnrichment'
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 5, borderColor: 'newNeutral.light5' }} />
                        <Box px={5}>
                            <Stack direction='row' alignItems='center' gap={2}>
                                <Typography component='h6' variant='newLabelMd' color='newNeutral'>
                                    Outputs
                                </Typography>
                                <Tooltip
                                    arrow
                                    placement='bottom'
                                    title={'tooltip content'}
                                    slotProps={{ tooltip: { sx: { bgcolor: 'newNeutral.main', color: '#fff' } } }}
                                >
                                    <Box display='inline-flex'>
                                        <Icon icon='lucide:info' color='newNeutral' size='sm' />
                                    </Box>
                                </Tooltip>
                            </Stack>
                            <Box mt={4}>
                                <Stack gap={3}>
                                    {fields.map((field, i) => (
                                        <Stack
                                            key={field.id}
                                            direction='row'
                                            justifyContent='space-between'
                                            alignItems='start'
                                            gap={2}
                                        >
                                            <Controller
                                                control={control}
                                                name={`outputs.${i}.name`}
                                                rules={{ required: 'field is required' }}
                                                render={({ field, fieldState }) => (
                                                    <Textfield
                                                        {...field}
                                                        disabled={disabled}
                                                        variant='outlined'
                                                        size='xs'
                                                        color='newEnrichment'
                                                        borderColor='newNeutral.light4'
                                                        placeholder='Enter...'
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                    />
                                                )}
                                            />
                                            {i > 0 && !disabled && (
                                                <Button
                                                    variant='text'
                                                    size='xs'
                                                    sx={{ p: 1, mt: 1, minWidth: 0 }}
                                                    onClick={() => remove(i)}
                                                >
                                                    <Icon icon='ph:trash-simple' color='newNeutral.light2' size='md' />
                                                </Button>
                                            )}
                                        </Stack>
                                    ))}
                                </Stack>
                                {!disabled && (
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        size='xs'
                                        color='newNeutral'
                                        sx={{
                                            mt: 3,
                                            bgcolor: 'newSurface.light',
                                            borderColor: 'newNeutral.light5',
                                            color: 'newNeutral',
                                            typography: 'newLabelSm'
                                        }}
                                        onClick={() => append({ name: '' })}
                                    >
                                        <Icon
                                            icon='fluent:add-circle-12-regular'
                                            size='sm'
                                            color='newNeutral'
                                            className='mr-1'
                                        />
                                        Add Output
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box flexGrow={1} />
                    <Box px={5}>
                        <CreditsOverview justifyContent='center' count={credits} suffix='Credits Usage' />
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            size='xs'
                            color='newNeutral'
                            sx={{ mt: 3, mb: 5 }}
                        >
                            <Icon icon='ri:sparkling-2-fill' size='md' color='#fff' className='mr-2' />
                            Run AI Agent
                        </Button>
                    </Box>
                </Stack>
            </Form>
        </Box>
    )
}
