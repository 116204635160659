import { type GridValidRowModel } from '@mui/x-data-grid-pro'
import BaseMuiDataGrid, { type Props as BaseDataTableProps } from 'src/components/shared/BaseMuiDataGrid'

export type Props<Row extends GridValidRowModel> = BaseDataTableProps<Row> & {}

const ExcelDataTable = <Row extends GridValidRowModel>({
    color = 'newNeutral',
    borderColor = 'rgb(224,224,224)',
    checkboxColor = 'newEnrichment',
    columnHeaderHeight = 36,
    rowHeight = 36,
    showCellVerticalBorder = true,
    showColumnVerticalBorder = true,
    disableRowSelectionOnClick = true,
    hideFooter = true,
    slotProps,
    sx,
    ...rest
}: Props<Row>) => {
    return (
        <BaseMuiDataGrid
            color={color}
            borderColor={borderColor}
            checkboxColor={checkboxColor}
            columnHeaderHeight={columnHeaderHeight}
            rowHeight={rowHeight}
            // getRowHeight={(row) => 'auto'}
            showCellVerticalBorder={showCellVerticalBorder}
            showColumnVerticalBorder={showColumnVerticalBorder}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            hideFooter={hideFooter}
            slotProps={{
                baseCheckbox: {
                    size: 'small',
                    color
                },
                ...slotProps
            }}
            sx={{
                '& .MuiDataGrid-columnHeaders': {
                    minHeight: 'auto !important',
                    maxHeight: 'auto !important',
                    '& .MuiDataGrid-columnHeader': {
                        bgcolor: 'newSurface.light3',
                        p: 0
                    }
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                    '& .MuiDataGrid-row': {
                        typography: 'newBodySm',
                        color: 'newNeutral.light2'
                    }
                },
                '& .MuiDataGrid-columnHeader.checkbox-header-cell': {
                    p: 0
                },
                '& .MuiDataGrid-cell.checkbox-content-cell': {
                    p: 0
                },
                '& .loader-header,.loader-cell': {
                    bgcolor: 'newPrimary.light5'
                },
                // '& .MuiCheckbox-root:not(.Mui-checked) svg': {
                //     color: 'newNeutral.light5'
                // },
                ...sx
            }}
            {...rest}
        />
    )
}

export default ExcelDataTable
