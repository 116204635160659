import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import RunService from 'src/components/new-enrichment/enrich/RunService'
import TestService from 'src/components/new-enrichment/enrich/TestService'
import { getBulk } from 'src/services/new-enrichment'
import type { CompanyContactEnrichService } from 'src/types/enrichment'

type TabValue = 'run-service' | 'test-service'
type Props = Omit<BoxProps, 'onSubmit'> & CompanyContactEnrichService & { bulkId: number; onSubmit?: () => void }

const tabs: { value: TabValue; label: string }[] = [
    {
        value: 'run-service',
        label: 'Run Service'
    },
    {
        value: 'test-service',
        label: 'Test Service'
    }
]

export default function BaseEnrichment({
    type,
    code,
    bulkId,
    credits,
    name,
    description,
    link,
    inputs,
    outputs,
    fields,
    onSubmit,
    ...rest
}: Props) {
    const [tab, setTab] = useState<TabValue>('run-service')
    const { isFetching: bulkLoading, data: bulk } = useQuery({
        initialData: null,
        queryKey: ['get-bulk', bulkId],
        queryFn: async () => {
            const bulk = await getBulk({ id: bulkId })
            return bulk
        }
    })
    const columnsOptions = bulk?.cellsDataNormalize.length
        ? bulk.cellsDataNormalize[0].map((header) => ({ label: `${header}`, value: `${header}` }))
        : []

    return (
        <Box display='flex' flexDirection='column' {...rest}>
            <Box px={5}>
                <Tabs
                    variant='fullWidth'
                    value={tab}
                    onChange={(_, val) => setTab(val)}
                    sx={{
                        p: 0.5,
                        minHeight: 0,
                        bgcolor: 'newGrey.light4',
                        borderRadius: 1,
                        '& .MuiTabs-indicator': { display: 'none' }
                    }}
                >
                    {tabs.map((t) => {
                        const isActive = tab === t.value
                        return (
                            <Tab
                                key={t.value}
                                value={t.value}
                                label={
                                    <Typography
                                        component='p'
                                        variant='newLabelMd'
                                        color={isActive ? 'newNeutral' : 'newNeutral.light2'}
                                        textTransform='capitalize'
                                    >
                                        {t.label}
                                    </Typography>
                                }
                                sx={{
                                    minHeight: 0,
                                    px: 5,
                                    py: 2,
                                    borderRadius: 1.2,
                                    border: isActive ? 1 : 0,
                                    borderColor: 'newNeutral.light5',
                                    bgcolor: isActive ? 'white' : 'transparent'
                                }}
                            />
                        )
                    })}
                </Tabs>
            </Box>
            <Box mt={5} flexGrow={1}>
                {tab === 'run-service' && (
                    <RunService
                        height={1}
                        type={type}
                        bulkId={bulkId}
                        bulkLoading={bulkLoading}
                        code={code}
                        credits={credits}
                        name={name}
                        description={description}
                        link={link}
                        inputs={inputs}
                        outputs={outputs}
                        fields={fields}
                        columnsOptions={columnsOptions}
                        onSubmit={onSubmit}
                    />
                )}
                {tab === 'test-service' && (
                    <TestService
                        height={1}
                        type={type}
                        bulkId={bulkId}
                        bulkLoading={bulkLoading}
                        code={code}
                        credits={credits}
                        name={name}
                        outputs={outputs}
                        fields={fields}
                        columnsOptions={columnsOptions}
                    />
                )}
            </Box>
        </Box>
    )
}
