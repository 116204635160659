import { create } from 'zustand'
import authConfigs from 'src/configs/auth'
import { getPlanNameFromPriority } from 'src/utils/plans'
import { UserStateModel, UserStateActions } from 'src/types/auth/User'
import { PlanPriority } from 'src/shared/types/services'

const useUser = create<UserStateModel & UserStateActions>((set, get) => ({
    // state
    forgetPasswordStep: 1,
    user: null,
    // getters
    isAuth: () => {
        const state = get()
        return !!state.user?.token
    },
    getFullName: () => {
        const state = get()
        return `${state.user?.firstName || '---'} ${state.user?.lastName || '---'}`
    },
    isFreePlan: () => {
        const state = get()
        return state.user?.plan?.priority === PlanPriority.FREE
    },
    getPlanName: () => {
        const state = get()
        const priority = state.user?.plan?.priority
        const planName = typeof priority !== 'undefined' && getPlanNameFromPriority(priority)
        return planName || ''
    },
    // actions
    setUser: async (newUser) => {
        localStorage.setItem(authConfigs.storageApiKeyName, newUser.api_key)
        localStorage.setItem(authConfigs.storageTokenKeyName, newUser.token)
        localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
        return set(() => ({ user: newUser }))
    },
    updateUser: (newUser) => set((state) => ({ user: { ...state.user!, ...newUser } })),
    updatePlan: (newPlan) => {
        const state = get()
        if (state.user) {
            const newUser = { ...state.user, plan: newPlan }
            localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
            return set(() => ({ user: newUser }))
        }
    },
    updateCredits: (newCredits: number) => {
        const state = get()
        if (state.user) {
            const newUser = { ...state.user, credits: newCredits }
            localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
            return set(() => ({ user: newUser }))
        }
    },
    updateForgetPasswordStep: (newStep) => set(() => ({ forgetPasswordStep: newStep })),
    logout: () => {
        localStorage.removeItem(authConfigs.storageApiKeyName)
        localStorage.removeItem(authConfigs.storageTokenKeyName)
        localStorage.removeItem(authConfigs.storageUserKeyName)
        return set(() => ({ user: null }))
    }
}))

export default useUser

//? Usage inside react files(components,hooks,...):
// import useUser from 'src/store/user'
// const email = useUser(state=>state.user.email)
// const logout = useUser(state=>state.logout)
// logout()
//? Usage outside of react files:
// import useUser from 'src/store/user'
// const store = useUser.getState()
// store.user.email
// store.logout()
