// ** shared imports
import { PlanModel } from 'src/shared/models'

type PlanType = 'monthly' | 'yearly'
type PlanEngine = 'lead' | 'enrichment'
export enum PlanPriority {
    FREE = 0,
    GROWTH,
    PREMIUM,
    UNLIMITED
}

export enum CustomPlanType {
    MONTHLY = 'monthly',
    YEARLY = 'yearly'
}

export enum CustomPlanEngine {
    LEAD = 'lead',
    ENRICHMENT = 'enrichment'
}

type FAQ = {
    id: number
    question: string
    answer: string
}

type ActivePlanModel = {
    name: string
    price: number
    total_credits: number
    remain_credits: number
    paused: boolean
    canceled: boolean
    period: 'monthly' | 'yearly'
    total_days: number
    remaining_days: number
    start_date: string
    end_date: string
    will_resume_at: number | null
    will_cancel_at: number | null
}

type CancelPlanParams = {
    reason: string
    description: string
}

type PausePlanParams = {
    month: number
    reason?: string
}

type PlansListAPIResponse = {
    plans: Array<PlanModel>
    custom_plans: Array<PlanModel>
    customer_email: string | null | undefined
}

type UpdateSubscriptionApiResponse = {
    plan: {
        type: 'monthly' | 'yearly'
        price_id: string
        name: string
        price: number
        credits: number
        category: 'lead-gen' | 'enrichment-engine'
    }
}

type ActivePlanApiResponse = ActivePlanModel

export type {
    PlanType,
    PlanEngine,
    FAQ,
    ActivePlanModel,
    CancelPlanParams,
    PausePlanParams,
    PlansListAPIResponse,
    UpdateSubscriptionApiResponse,
    ActivePlanApiResponse
}
